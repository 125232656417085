<!--

	Pagination component
	Displays Numbered Links
	Emits gotoPage()

	<MhPagination
		:currentIndex="_.get( $route, 'query.paged', 1 )"
		:countIndexes="countPaginationIndexes"
		@gotoPage="(gotoIndex)=>{ $router.push({query: {paged: gotoIndex}}) }">
		<template v-slot:prevButton> ← </template>
		<template v-slot:nextButton> → </template>
	</MhPagination>

	Props
		currentIndex	Number
		countIndexes	Number

	Slots (optional)
		prevButton
		nextButton

	Events
		gotoPage( newPageNumber )

	TODO:
		Styling with ... if there are too many.
		Good Examples here:
			https://css-tricks.com/archives/
			https://css-tricks.com/archives/page/2/
			https://css-tricks.com/archives/page/3/

	2021-02-26	refactor: added slots for prev and next buttons.
	2019-06-06	init

-->

<template>
	<div class="MhPagination">
		<div class="MhPagination__inner">
			<!-- prev button -->
			<template v-if="showPrevLink">
				<template v-if="$slots.prevButton">
					<span class="MhPagination__item MhPagination__item--prevButton">
						<a 	class="MhPagination__link MhPagination__link--prevButton"
							role="button"
							@click="$emit('gotoPage', currentIndex-1)">
							<slot name="prevButton"></slot>
						</a>
					</span>
				</template>
				<template v-else>
					<span class="MhPagination__item MhPagination__item--prevButton">
						<a class="MhPagination__link MhPagination__link--prevButton"
						   role="button"
						   @click="$emit('gotoPage', currentIndex-1)">
						   <span>←</span>
					 	</a>
					</span>
				</template>
			</template>
			<!-- pagination items: numbers and ... -->
			<span class="MhPagination__paginationItems">
				<span class="MhPagination__item"
					  :class="{
						  'MhPagination__item--isActive'  : item == currentIndex,
						  'MhPagination__item--separator' : !_.isNumber(item),
						  'MhPagination__item--link'      : _.isNumber(item),
					  }"
					  v-for="(item, index) in paginationItems" :key="index">
					  <template v-if="_.isNumber(item)">
						  <a  class="MhPagination__link MhPagination__link--pageNumber"
							  :class="{'MhPagination__link--isActive' : item == currentIndex}"
							  role="button"
							  @click="$emit('gotoPage', item)"
						  ><span>{{item}}</span></a>
					  </template>
					  <template v-else>
						  <span class="MhPagination__separator">{{item}}</span>
					  </template>
				</span>
			</span>
			<!-- next button -->
			<template v-if="showNextLink">
				<template v-if="$slots.nextButton">
					<span class="MhPagination__item MhPagination__item--nextButton">
						<a 	class="MhPagination__link MhPagination__link--nextButton"
							role="button"
							@click="$emit('gotoPage', currentIndex+1)">
							<slot name="nextButton"></slot>
						</a>
					</span>
				</template>
				<template v-else>
					<span class="MhPagination__item MhPagination__item--nextButton">
						<a class="MhPagination__link MhPagination__link--nextButton"
						   role="button"
						   @click="$emit('gotoPage', currentIndex+1)">
						   <span>→</span>
						</a>
					</span>
				</template>
			</template>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'MhPagination',
		components: {},
		mixins: [],
		props: {
			currentIndex: {
				type: [String, Number],
				default: 1,
			},
			countIndexes: {
				type: [String, Number],
				default: 0,
			},
		},
		data() {
			return {
				maxIndexes : 10, // after this we will use ... in markup
				paginationItems : [],
			}
		},
		watch: {
			countIndexes: {
				handler: function( to, from, doLog = false ) {
					const currentIndex = parseInt( this.currentIndex )
					const countIndexes = parseInt( to )
					let items = []

					if( countIndexes ){
						//items.push( 1 )
						//items.push( countIndexes )
					}

					//*
					for(let i = 1; i < countIndexes+1; i++) {
						if( i == 1 ){ // first
							items.push( i )
						}
						else if( i == countIndexes ){ // last
							items.push( i )
						}

						else if( i == currentIndex-2){ items.push( i ) }
						else if( i == currentIndex-1){ items.push( i ) }
						else if( i == currentIndex){   items.push( i ) }
						else if( i == currentIndex+1){ items.push( i ) }
						else if( i == currentIndex+2){ items.push( i ) }
						else if( i < currentIndex){    items.push( '<' ) }
						else if( i > currentIndex){    items.push( '>' ) }
					}

					// remove double < or >
					items = this._.uniq( items )

					// replace < and > with ...
					const i1 = this._.indexOf(items, '<')
					const i2 = this._.indexOf(items, '>')

					if( i1 > -1 ) items[i1] = '…'
					if( i2 > -1 ) items[i2] = '…'

					if( doLog ){
						console.group(this.$options.name + '• watch: countIndexes')
						console.log('countIndexes:', countIndexes)
						console.log('items.length:', items.length)
						console.log('items:', items)
						console.log('i1:', i1)
						console.log('i2:', i2)
						console.groupEnd()
					}

					this.paginationItems = items
				},
				immediate : true,
				deep: false,
			}
		},
		computed: {
			showPrevLink(){
				const currentIndex = parseInt( this.currentIndex )
				const countIndexes = parseInt( this.countIndexes )
				let doShow = false

				if( countIndexes > 0 &&
					currentIndex > 1 ){
					doShow = true
				}

				return doShow
			},
			showNextLink(){
				const currentIndex = parseInt( this.currentIndex )
				const countIndexes = parseInt( this.countIndexes )
				let doShow = false

				if( countIndexes > 0 &&
					currentIndex < countIndexes ){
					doShow = true
				}

				return doShow
			},
		},
		methods: {
			getItemClasses(link, index){
				const currentIndex = parseInt( this.currentIndex )
				const classes = []

				if( currentIndex == index+1 ){
					classes.push('MhPagination__link--isActive')
				}

				return classes
			},
		},
		created(){},
		mounted( doLog = false ){
			// groupCollapsed group
			if( doLog ){
				console.groupCollapsed(this.$options.name + '• mounted()')
				//console.log('countIndexes:', this.countIndexes)
				console.groupEnd()
			}
		},
	}
</script>

<style lang="less">
	@import "../../less/vars.less";
	@import "../../less/mixins.less";

	@MhPagination : {
		//height : 2em;
	}

	.MhPagination {	// layout
		max-width: 100%;

		&__inner {
			display: flex;
			flex-wrap: wrap;
		}
		/*
		&__numbers {
			display: flex;
			flex-wrap: wrap;
		}
		*/
	}
	.MhPagination {	// styling
		@item : {
			height: 2.5em;
		}

		/*
		MhPagination__item MhPagination__link MhPagination__link--prevButton
		MhPagination__numbers
			MhPagination__item MhPagination__link MhPagination__link--pagedIndex
			MhPagination__item MhPagination__link MhPagination__link--pagedIndex
			MhPagination__item MhPagination__link MhPagination__link--pagedIndex
		MhPagination__item MhPagination__link MhPagination__link--nextButton
		*/

		&__link {
			position: relative;
			//pading: 0.5em 0.8em;
			//opacity: 0.35;
			//color: inherit;
			//outline: 1px solid red;
		}

		/*
		&__link--arrow,
		&__link--number {
			height: @item[height];
			width: @item[height];
			line-height: @item[height];
			text-align: center;
		}
		&__link::after {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			border: 1px solid;
			content: "";
		}
		&__link:not(:last-child):not(:hover):not(&__link--isActive)::after {
			border-right: none;
		}
		&__link--isActive {
			opacity: 1;
			pointer-events: none;
			&::after { border-width: 2px; }
		}
		&__link:not(&__link--isActive):hover {
			opacity: 0.75;
		}
		*/

	}
</style>
