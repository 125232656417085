<template>
	<MhRouterView class="OvBlogPostsView view background2--color-primary-background" :isContentReady="isContentReady">

		<div class="minHeight minHeight--100 vSpace vSpace--viewContent">

			<OvMainHeadline
				:text="$t('title.blog')"
			></OvMainHeadline>

			<OvSpacer :type="'default'" :rows="6"></OvSpacer>
			<OvBlogPostsList
				:perPage="20"
				:showPagination="true"
			></OvBlogPostsList>

		</div>
		<OvMainFooter></OvMainFooter>
	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js' // TODO: remove this
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	import MhRouterView from '@/components/MhRouterView/MhRouterView.vue'
	import OvMainHeader from '@/components/OvMainHeader.vue'
	import OvMainHeadline from '@/components/OvMainHeadline.vue'
	import OvBlogPostsList from '@/components/OvBlogPostsList.vue'
	import OvMainFooter from '@/components/OvMainFooter.vue'
	import OvSpacer from '@/components/OvSpacer.vue'

	export default {
		name: 'OvBlogPostsView',
		components: {
			MhRouterView,
			OvMainHeader,
			OvMainHeadline,
			OvBlogPostsList,
			OvMainFooter,
			OvSpacer,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {},
		data() {
			return {
				isContentReady: false,
			}
		},
		watch: {
			restHandler__pendingRequests: {
				handler( to, from, doLog = false ) {
					const icon = to ? '❌' : '✅'

					if( doLog ){
						console.groupCollapsed(this.$options.name, '• watch restHandler__pendingRequests', from, to, icon)
						console.groupEnd()
					}

					if( !to ) this.isContentReady = true
				},
				immediate : true,
			},
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
		},
		methods: {},
		mounted() {},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";

	.OvBlogPostsView {
		//background-color: yellow;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
