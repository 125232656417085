<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvBlogPostsList></OvBlogPostsList>

	2020-11-23	init

-->

<template>
	<div class="OvBlogPostsList contentModule" :class="elmClasses">
		<div class="OvBlogPostsList__inner contentModule__inner hSpace hSpace--app maxWidth maxWidth--app">
			<div class="OvBlogPostsList__header" v-if="showHeader">
				<h2 class="font font--bold font--sizeHuge">{{$t('title.news')}}</h2>
				<OvSpacer type="default" :rows="2"></OvSpacer>
			</div>
			<div class="OvBlogPostsList__posts">
				<router-link class="OvBlogPostsList__postTeaser hover hover--blogPostTeaser"
					:to="{ name: 'OvBlogPostView', params: { slug : post.slug } }"
					v-for="(post, i) in posts" :key="i">
					<div class="OvBlogPostsList__postTeaserDate miniTitleWithLine font font--bold font--sizeMini">
						{{app.getPostDate( post )}} <br/>
					</div>
					<div class="OvBlogPostsList__postTeaserTitleWrapper hyphenate">
						<h3 class="lineCrop">
							<OvLabel2
								class="OvBlogPostsList__postTeaserPageLabel font font--sizeSmall"
								:verticalAlign="'text-top'"
								:text="app.getPostLabelText( post )"
							></OvLabel2>
							<span class="font font--bold font--sizeMedium" v-html="app.getPostTitle( post )"></span>
						</h3>
						<span class="linkArrow font font--sizeMedium">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.08 21">
							  <path id="Pfad_64" data-name="Pfad 64" d="M2.55-11.433H21.3L15-17.862l2.34-2.418L27.63-9.78,17.34.72,15-1.7l6.3-6.429H2.55Z" transform="translate(-2.55 20.28)" fill="rgba(0,0,0,0.97)"/>
							</svg>
							<!--
							<OvLabel2
								class="OvLabel2--isArrow font font--sizeDefault"
								:bgColor="'white'"
								:verticalAlign="'text-bottom'"
								:showArrowAfter="true"
								:routerLink="{ name: 'OvEventView', params: { slug : post.slug } }"
							></OvLabel2>
							-->
						</span>
					</div>
					<div class="OvBlogPostsList__postTeaserExcerptWrapper hyphenate">
						<div class="font font--sizeSmall">
							{{app.getPostExcerpt( post )}}
						</div>
					</div>
					<!--
					<pre name="post">{{post}}</pre>
					-->
				</router-link>
			</div>
			<div class="OvBlogPostsList__footer vSpace vSpace--readMoreFooter" v-if="showFooter">
				<OvLabel2
					:class="{'font--sizeMedium' : ['md', 'sm', 'xs'].includes($mq)}"
					:text="$t('button.moreBlogPosts')"
					bgColor="white"
					textColor="currentColor"
					XXXshowArrowAfter="true"
					:routerLink="{ name: 'OvBlogPostsView' }"
				></OvLabel2>
			</div>
			<div class="OvBlogPostsList__pagination" v-if="showPagination">
				<br/>
				<br/>
				<br/>
				<MhPagination
					:currentIndex="_.get( $route, 'query.paged', 1 )"
					:countIndexes="countPaginationIndexes"
					@gotoPage="(gotoIndex)=>{ $router.push({query: {paged: gotoIndex}}) }">
					<template v-slot:prevButton>
						<OvLabel2
							bgColor="white"
							textColor="currentColor"
							:showArrowBefore="true"
							:showArrowAfter="false"
						></OvLabel2>
					</template>
					<template v-slot:nextButton>
						<OvLabel2
							bgColor="white"
							textColor="currentColor"
							:showArrowBefore="false"
							:showArrowAfter="true"
						></OvLabel2>
					</template>
				</MhPagination>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import OvLabel2 from '@/components/OvLabel2.vue'
	import OvSpacer from '@/components/OvSpacer.vue'
	import MhPagination from '@/components/MhPagination/MhPagination.vue'

	export default {
		name: 'OvBlogPostsList',
		components: {
			OvLabel2,
			OvSpacer,
			MhPagination,
		},
		mixins: [RestHandler],
		props: {
			perPage: {
				type: [Number, Boolean],
				default: 6,
			},
			showHeader: {
				type: [String, Boolean],
				default: false,
			},
			showFooter: {
				type: [String, Boolean],
				default: false,
			},
			showPagination: {
				type: [String, Boolean],
				default: false,
			},
		},
		data() {
			return {
				posts : null,
				countPaginationIndexes : 0, // is set after fetchPosts
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			fetchPosts( doLog = false ){
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'post',
						perPage : this.perPage,
						paged : this._.get( this.$route, 'query.paged', 1 ),
					},
					callback : (response) => {
						let result = response.data.result
						let paginationLinks = response.data.info.paginationLinks

						this.posts = result
						this.countPaginationIndexes = paginationLinks.length

						if( doLog ){
							//console.groupCollapsed('OvEventsList • fetchEvents() callback')
							console.groupCollapsed('OvBlogPostsList • fetchEvents() callback')
							console.log('response:', response)
							console.log('paginationLinks:', paginationLinks)
							console.log('result:', result)
							console.groupEnd()
						}
					},
				})
			},
		},
		created(){},
		mounted( doLog = false ){
			this.fetchPosts()

			// groupCollapsed group
			if( doLog ){
				console.group(this.$options.name + '• mounted()')
				console.log('this.$route:', this.$route)
				console.groupEnd()
			}
		},
		destroyed() {},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	XXX.OvBlogPostsList { // debug
		background-color: fade( red, 20 );

		&__postTeaser {
			outline: 1px solid red;
		}
		&__postTeaser {
			&DateLine {
				background-color: fade( red, 20 );
			}
			&TitleWrapper {
				background-color: fade( green, 20 );
			}
			&ExcerptWrapper {
				background-color: fade( blue, 20 );
			}
		}
	}
	.OvBlogPostsList { // layout
		&__posts {
			.grid;
			.grid--colGap;
			.grid--cols-2;

			@media @mq[md] {
				.grid--cols-1;
			}
		}
	}
	.OvBlogPostsList { // styling
		&__postTeaser {
			display: block;
			color: inherit;
			padding: 0.25em 0;

			&Date {}
			&TitleWrapper {
				display: flex;
				padding-top: 0.25em;
				padding-right: 0.35em;
				padding-bottom: 0.35em;

				& > *:nth-child(1) { // Title
					flex-grow: 1;
					padding-right: 0.5em;
				}
				& > *:nth-child(2) { // Arrow
				}
			}
			&PageLabel {
				margin-right: 0.75em;
			}
			&ExcerptWrapper {
				//padding: 0 0.25em;
				padding-bottom: 1em;
			}
		}
		&__postTeaser:hover &__postTeaserDateLineDate,
		//&__postTeaser:hover &__postTeaserDateLineLine,
		&__postTeaser:hover &__postTeaserTitleWrapper,
		&__postTeaser:hover &__postTeaserExcerptWrapper {
			transition: all 0.1s ease;
			color: var(--color-primary-baseLink);
		}

		&__footer {
			//background-color: fade( green, 50 );
			display: flex;
			flex-direction: column;
			//padding-right: 0.25em;

			.OvLabel2 {
				align-self: flex-end;
			}
		}
	}

	/*
	MhPagination__item
		MhPagination__link MhPagination__link--prevButton
	MhPagination__paginationItems
		MhPagination__item
			MhPagination__link MhPagination__link--pageNumber
		MhPagination__item--isActive
			MhPagination__link MhPagination__link--pageNumber MhPagination__link--isActive
		MhPagination__item
			MhPagination__separator
		MhPagination__item
			MhPagination__link MhPagination__link--pageNumber
	MhPagination__item
		MhPagination__link MhPagination__link--nextButton
	*/

	.MhPagination {
		//background-color: fade( red, 20 );

		&__item--prevButton {
			margin-right: 0.25em;
		}
		&__paginationItems {
			display: flex;
		}
		&__item--nextButton {
			margin-left: 0.25em;
		}

		&__item {
			display: flex;
		}
		&__item--link {
			display: flex;
			height: 1.25em; width: 1.25em;
			border-radius: 50%;
			overflow: hidden;
		}

		&__link {
			color: inherit;
		}
		&__link--pageNumber {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;

			transition: all 0.1s ease;
			font-size: 0.8em;
			line-height: 0.8em;

			text-decoration: none !important;
			opacity: 0.5;

			&:hover { opacity: 1;}
		}
		&__link--isActive {
			background-color: currentColor;
			pointer-events: none;
			opacity: 1;

			span {
				//color: white;
				color: var(--color-primary-background);
			}
		}
		.OvLabel2 {
			height: 100%;
		}

		@media @mq[md] {
			font-size: 1.5em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
