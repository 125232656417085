<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvSpacer></OvSpacer>

	2020-11-23	init

-->

<template>
	<div class="OvSpacer" :class="elmClasses">
		<div class="OvSpacer__inner">
			<div class="OvSpacer__row" v-for="(row, index) in rows" :key="index"></div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'OvSpacer',
		components: {},
		mixins: [],
		props: {
			type: {
				type: [String, Boolean],
				default: 'default',
			},
			rows: {
				type: [Number, Boolean],
				default: 1,
			},
		},
		data() {
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.type) classes.push('OvSpacer--type' + this._.upperFirst(this.type) )

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
		destroyed () {},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvSpacer {
		[showBorders2] & {
			background-color: fade( orange, 50 );
			outline: 1px solid fade( orange, 75 );
		}

		&__row {
			[showBorders3] & {
				outline: 1px dotted fade( brown, 25 );
			}
		}
		&--typeDefault &__row {
			height: 0.8333em; // 20px Höhe / 24px Default-Schriftgröße

			@media @mq[md] { height: 0.5em; }
			@media @mq[sm] {}
		}
		&--typeMedium &__row {
			height: 1.666em;  // 40px Höhe / 24px Default-Schriftgröße

			@media @mq[md] { height: 1.1em; }
			@media @mq[sm] {}
		}
		&--typeBig &__row {
			height: 8.333em;  // 200px Höhe / 24px Default-Schriftgröße
			height: 4.333em;  // 200px Höhe / 24px Default-Schriftgröße

			@media @mq[md] { height: 5em; }
			@media @mq[sm] {}
		}
		&--typeAfterExhibitionTeaser &__row {
			//background-color: red;
			height: 1em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
